const { get } = require('./get')

const getCurrentItem = player => {
    if (player.playlist && typeof player.playlist === 'function' && typeof player.playlist.currentItem === 'function') {
        const currInd = player.playlist.currentItem()
        const playlist = player.playlist() || []
        const curr = playlist[currInd] || {}

        return curr
    } else {
        console.warn('Unable to get current item for player')
    }
}

function whenPlayerReady(player, fn) {
    if (player && typeof player.ready === 'function') {
        player.ready(function () {
            fn()
        })
    }
}

function filterOutOnHoldAssets(playlist) {
    return playlist.filter(asset => {
        const hasDuration = !!get(['data', 'media', 'data', 'duration'], asset)

        // if duration is present, the asset status is not 'on hold'
        return hasDuration
    })
}

function filterOutItemsWithNoSource(items) {
    return items.filter(item => {
        return item && !!item.source
    })
}

const tryToAutoplayVideo = (player, shouldAutoplay) => {
    if (!shouldAutoplay) {
        return
    }

    if (typeof window !== 'undefined' && typeof player.play === 'function') {
        try {
            player.play() // autoplay
        } catch (err) {
            console.log('Caught error attempting to autoplay video:', err)
        }
    } else {
        console.warn('unable to autoplay player:', player)
    }
}

function setNowPlayingTitle(player, title) {
    const controlBar = player.getChild('ControlBar') || {}
    const nowPlaying = controlBar.getChild('NowPlaying')

    if (nowPlaying) {
        nowPlaying.trigger('nowplayingitemchange', { data: title })
    }
}

function setBeforePlaylistItemChangeListener(player, callback) {
    if (typeof player.on === 'function') {
        player.on('beforeplaylistitem', (ev, item) => {
            if (typeof callback === 'function') {
                callback()
            }
        })
    }
}

function setPlaylistItemChangeListener(player, callback) {
    if (typeof player.on === 'function') {
        const onPlaylistItemChange = (ev, item) => {
            setNowPlayingTitle(player, item.title)
            if (typeof callback === 'function') {
                callback(player)
            }
        }
        player.on('playlistitem', onPlaylistItemChange)

        return onPlaylistItemChange
    }
}

function disableVideoContextMenu(videoWrapperId) {
    const video = document.querySelector(`#${videoWrapperId} video` || 'video')

    if (video) {
        video.addEventListener('contextmenu', ev => {
            // disable right click context menu to prevent casual users from
            // downloading the video
            ev.preventDefault()
        })
    }
}

function isEpgPlayerInBackground() {
    if (typeof window !== 'undefined') {
        const isMobile = window.innerWidth <= 672
        const isOnMobileEpgScreen = window.location.pathname === '/'

        return isMobile && isOnMobileEpgScreen
    }
}

export {
    disableVideoContextMenu,
    filterOutItemsWithNoSource,
    filterOutOnHoldAssets,
    getCurrentItem,
    isEpgPlayerInBackground,
    setNowPlayingTitle,
    setBeforePlaylistItemChangeListener,
    setPlaylistItemChangeListener,
    tryToAutoplayVideo,
    whenPlayerReady,
}
