async function loadSpotXSdk(player, publisherId, channelId, adSlotId, videoSlotId) {
    if (typeof SpotX === 'undefined') {
        // this should have already been preloaded
        return loadScriptAsync(`https://js.spotx.tv/directsdk/v1/${publisherId}.js`)
            .then(() => {
                return initializeSpotXSdk(player, channelId, adSlotId, videoSlotId, publisherId)
            })
            .catch(err => {
                console.error('error playing ad', err)
            })
    }

    return initializeSpotXSdk(player, channelId, adSlotId, videoSlotId)
}

function initializeSpotXSdk(player, channelId, adSlotId, videoSlotId, publisherId) {
    const adSlot = document.querySelector(`#${adSlotId}`)
    const videoSlot = document.querySelector(`#${videoSlotId}`)

    /* eslint-disable-next-line */
    const sdk = new SpotX.DirectAdOS({
        slot: adSlot,
        video_slot: videoSlot,
        ad_volume: 0,
        hide_skin: true,
        ad_mute: true,
        autoplay: false,
        channel_id: channelId,
        content_width: player.width_,
        content_height: player.height_,
        ...getSpotxGdprOptions(),
    })

    if (typeof window !== 'undefined') {
        window.directAdOS = sdk
    }

    return sdk
}

function getSpotxGdprOptions() {
    let hasConsent = false
    try {
        hasConsent = document.cookie.includes('cookieconsent_status=allow')
    } catch (err) {
        console.warn('unable to check cookie consent:', err)
    }

    return {
        ados: {
            gdpr_consent: hasConsent ? 1 : 0,
            gdpr: 1,
            cmp: {
                ignore: true, // don't try to fetch consent data
            },
        },
    }
}

const loadScriptAsync = uri => {
    return new Promise((resolve, reject) => {
        const tag = document.createElement('script')
        tag.src = uri
        tag.async = true
        tag.onload = () => {
            resolve()
        }
        tag.onerror = err => {
            reject(err)
        }
        const firstScriptTag = document.getElementsByTagName('script')[0]
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)
    })
}

function onAdLog(stringifiedEventDetails) {
    // Parse event details into JS Object and access event detail attributes
    const eventDetails = JSON.parse(stringifiedEventDetails)

    if (eventDetails.type === 'AdError') {
        // In this case, an error was published by an ad, and has been removed from the auction.
        // Do any logging/debugging based on provided event details...
        console.error('Caught ad error:', eventDetails)
    } else if (eventDetails.type === 'AdLoaded') {
        // An ad has been loaded. You can use above details to take any actions you like.
        // Maybe change the skin of your player...
        console.log('Ad loaded:', eventDetails)
    }
}

export { loadScriptAsync, loadSpotXSdk, onAdLog }
