const axios = require('axios')
const RequestError = require('./requestError')

function get(p, o) {
    return p.reduce((xs, x) => (xs && xs[x] ? xs[x] : null), o)
}

class Remoco {
    constructor() {
        this.apiUrl = this.getAwsApiGatewayUrl()
        this.CancelToken = axios.CancelToken
        this.cancel = null
        this.apiKey = process.env.GATSBY_API_GATEWAY_KEY
        this.appIdentifier = this.getAppIdentifier()
    }

    makeRequest(endpoint, data, chCode, shouldCancel = false) {
        if (shouldCancel && typeof this.cancel === 'function') {
            this.cancel()
            this.cancel = null
        }

        return axios
            .request({
                data,
                method: data ? 'post' : 'get',
                url: this.apiUrl + endpoint,
                headers: {
                    Accept: 'application/json',
                    'Accept-Language': 'en-US',
                    'x-api-key': this.apiKey,
                    'Account-Code': 'videoelephant',
                    'Channel-Code': chCode || 'videoelephant',
                },
                cancelToken: new this.CancelToken(c => {
                    this.cancel = c
                }),
            })
            .then(this.handleResponse)
            .catch(this.handleError)
    }

    getAppIdentifier() {
        const env = process.env.GATSBY_REMOCO_ENVIRONMENT

        return env !== 'production' ? 'videoelephant' : 'com.videoelephant.tv'
    }

    getAwsApiGatewayUrl() {
        return 'https://ypz911gb-videoelephant.api.ediflo.tv'
    }

    getCategory(identifier, expandLevel = 2, limit, offset, order, sortKeys) {
        const limitParam = limit ? `&limit=${limit}` : ''
        const addOffset = typeof offset === 'number' ? `&offset=${offset}` : ''
        const orderParam = order ? `&order=${order}` : '&order=DESC'
        const sortKeysParam = sortKeys ? `&sort_keys=${sortKeys}` : '&sort_keys=episode_number'
        const path = `/categories/${identifier}?expand=${expandLevel}${limitParam}${addOffset}${orderParam}${sortKeysParam}`

        return this.makeRequest(path)
    }

    getCategoryEvents(identifier, expandLevel = 2, limit, offset, order, sortKeys) {
        const limitParam = limit ? `&limit=${limit}` : ''
        const addOffset = typeof offset === 'number' ? `&offset=${offset}` : ''
        const orderParam = order ? `&order=${order}` : '&order=DESC'
        const sortKeysParam = sortKeys ? `&sort_keys=${sortKeys}` : '&sort_keys=episode_number'
        const path = `/categories/${identifier}/events?expand=${expandLevel}${limitParam}${addOffset}${orderParam}${sortKeysParam}`

        return this.makeRequest(path)
    }

    getCollection(identifier, expandLevel = 2, limit, offset, order, sortKeys, parts) {
        const limitParam = limit ? `&limit=${limit}` : ''
        const addOffset = typeof offset === 'number' ? `&offset=${offset}` : ''
        const orderParam = order ? `&order=${order}` : ''
        const sortKeysParam = sortKeys ? `&sort_keys=${sortKeys}` : ''
        const partsParam = parts ? `&parts=${parts}` : ``
        const path = `/collections/${identifier}?expand=${expandLevel}${limitParam}${addOffset}${orderParam}${sortKeysParam}${partsParam}`

        return this.makeRequest(path)
    }

    getConfig() {
        const path = `/apps`

        return this.makeRequest(path)
    }

    getGeoip() {
        const path = '/bots/geoip'

        return this.makeRequest(path)
    }

    getLibraryEventById(id) {
        return this.makeRequest('/events/' + id + '?expand=2&parts=series')
    }

    getPlannedEvents(start, end, channelCode, offset, limit, expand) {
        const addOffset = typeof offset === 'number' ? `&offset=${offset}` : ''
        const addLimit = typeof limit === 'number' ? `&limit=${limit}` : '&limit=100'
        const addExpand = expand === false ? '?expand=1' : '?expand=2&parts=event,series'
        const path = `/plan/${start},${end}${addExpand}${addLimit}${addOffset}`

        return this.makeRequest(path, undefined, channelCode)
    }

    getSeriesById(identifier, expandLevel = 2, limit, offset, parts, order, sortKeys) {
        const limitParam = limit ? `&limit=${limit}` : ''
        const addOffset = typeof offset === 'number' ? `&offset=${offset}` : ''
        const partsParam = parts ? `&parts=${parts}` : ''
        const orderParam = order ? `&order=${order}` : '&order=DESC'
        const sortKeysParam = sortKeys ? `&sort_keys=${sortKeys}` : '&sort_keys=episode_number'
        const path = `/series/${identifier}?expand=${expandLevel}${partsParam}${limitParam}${addOffset}${orderParam}${sortKeysParam}`
        return this.makeRequest(path)
    }

    search(searchTerm = '', limit, collections, filter, expandLevel = 2, parts) {
        const limitParam = limit ? `&limit=${limit}` : ''
        const collectionsParam = collections ? `&collections=${collections}` : ''
        const filterParam = filter ? `&filter=${filter}` : ''
        const addExpand = expandLevel ? `&expand=${expandLevel}` : ''
        const partsParam = parts ? `&parts=${parts}` : ''
        const path = `/search?q=${searchTerm}${addExpand}${filterParam}${partsParam}${limitParam}${collectionsParam}`
        const shouldCancel = true

        return this.makeRequest(path, undefined, undefined, shouldCancel)
    }

    handleResponse(res) {
        if (get(['data', 'status'], res) === 'success') {
            return res.data
        } else {
            throw new RequestError('not-success', 'error_fetch', res)
        }
    }

    handleError(err) {
        if (get(['constructor', 'name'], err) === 'Cancel') {
            return
        }

        if (err.response) {
            throw new RequestError(err.message, err.response.status, err.response)
        } else if (err.request) {
            throw new RequestError(err.message, err.request.status, err.request)
        } else {
            throw new RequestError(err.message, '500')
        }
    }
}

const remocoClient = new Remoco()

module.exports = remocoClient
