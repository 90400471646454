import actionTypes from './actionTypes'
import { createStore as reduxCreateStore } from 'redux'

const reducer = (state, action) => {
    switch (action.type) {
        case actionTypes.SET_CHANNEL: {
            return {
                ...state,
                channel: action.channel,
            }
        }
        case actionTypes.SET_DYNAMIC_CONTENT_SERIES_TITLES: {
            return {
                ...state,
                titles: action.titles,
            }
        }
        case actionTypes.SET_CONFIG_DATA: {
            return {
                ...state,
                config: action.config,
            }
        }
        case actionTypes.SET_SERIES_CODE: {
            return {
                ...state,
                seriesIdsToCodesMap: {
                    ...state.seriesIdsToCodesMap,
                    ...action.seriesIdToCodeMap,
                },
            }
        }
        case actionTypes.SET_EPG_PLAYER_MUTE: {
            return {
                ...state,
                epgPlayerMute: action.mute,
            }
        }
        case actionTypes.SET_VOD_PLAYER_MUTE: {
            return {
                ...state,
                vodPlayerMute: action.mute,
            }
        }
        case actionTypes.SET_COOKIE_CONSENT_STATUS: {
            return {
                ...state,
                cookieConsentStatus: action.consented,
            }
        }
        default: {
            // console.log('Unrecognized action type:', action.type)
            break
        }
    }

    return state
}

const initialState = { channel: {} }

let store

const createStore = () => {
    if (store) {
        return store
    }

    store = reduxCreateStore(
        reducer,
        initialState,
        typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION__
            ? window.__REDUX_DEVTOOLS_EXTENSION__()
            : undefined
    )

    return store
}

export default createStore
