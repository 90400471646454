class RequestError extends Error {
    constructor(message, eCode, res) {
       super(message);
       this.name = 'RequestError';
       this.errorCode = eCode;
       this.response = res;
    }
}

module.exports = RequestError;
