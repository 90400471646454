const remocoClient = require('../remocoClient')
const isoLangs = require('./language-codes').default

function get(p, o) {
    const isDefined = x => typeof x !== 'undefined'

    return p.reduce((xs, x) => (xs && isDefined(xs[x]) ? xs[x] : null), o)
}

function getRelevantEntriesData(entries) {
    return entries.map(entry => getRelevantItemData(entry))
}

function getRelevantItemData(entry) {
    return {
        category: get(['data', 'categories', 'list', '0', 'id'], entry) || '',
        categoryTitle: get(['data', 'categories', 'list', '0', 'data', 'title'], entry) || '',
        company: get(['data', 'production', 'company'], entry),
        ctitle: get(['data', 'title'], entry),
        description: get(['data', 'synopsis'], entry),
        duration: get(['data', 'video', 'data', 'duration'], entry),
        eventCode: get(['data', 'event_code'], entry),
        id: entry.id,
        image: getImageUrl(entry, 'poster_16-9'),
        image21x9: getImageUrl(entry, 'poster_21-9'),
        isChannel: isChannel(entry),
        language: getLanuage(entry),
        locked: false,
        marketingSynopsis: get(['data', 'marketing_synopsis'], entry),
        marketingTitle: get(['data', 'marketing_title'], entry),
        series: get(['relationships', 'series', 'id'], entry) || '',
        seriesTitle: get(['relationships', 'series', 'data', 'title'], entry),
        source: get(['data', 'video', 'data', 'source'], entry),
        slug: getSlugWithTypeIndicator(entry),
        type: entry.type,
        subtype: get(['data', 'type'], entry),
    }
}

function getLanuage(entry) {
    const lCode = get(['data', 'production', 'language'], entry)

    // using English name instead of native
    return get([lCode, 'name'], isoLangs)
}

function getImageUrl(item, imageType) {
    const url = get(['data', 'resources', imageType, 'value'], item) || getFallbackImage(imageType)

    return url.replace(/\/\d+x\d+.([a-z]+)$/, '/original.$1')
}

function getFallbackImage(imageType) {
    if (['poster_16-9', 'poster_16x9'].includes(imageType)) {
        return '/video_thumbnail_placeholder.png'
    } else if (['poster_21-9', 'poster_21x9'].includes(imageType)) {
        return '/video_thumbnail_placeholder21x9.png'
    }
    console.error('Unrecognized image type:', imageType)
}

function getSlugWithTypeIndicator(entry) {
    const slug = get(['data', 'slug'], entry) || entry.id

    if (entry.type === 'LibrarySeries') {
        return `s/${slug}`
    } else if (entry.type === 'LibraryEvent') {
        return `i/${slug}`
    } else if (entry.type === 'AdminCategory') {
        return `c/${slug}`
    } else {
        console.error('Unsupported entry type:', entry.type)
    }
}

function isChannel(entry) {
    const subtype = get(['data', 'type'], entry)

    return ['channel-stream', 'channel-collection'].includes(subtype)
}

function getHref({ isChannel, slug }) {
    return isChannel ? `/player/${slug}` : `/watch/${slug}`
}

function getIdentifierFromPath({ id, location }) {
    if (id) {
        return id
    }

    let path = ''

    if (location) {
        // get ID from slug
        const { pathname } = location

        path = pathname
    }

    if (typeof window !== 'undefined') {
        path = window.location.pathname
    }

    if (path.substring(0, 6) === '/watch') {
        return path.slice(9) // cut off /watch/s/, /watch/i/, /watch/c/
    } else if (path.substring(0, 7) === '/player') {
        return path.slice(10) // cut off /player/s/, /player/i/, /player/c/
    }
}

const getExpandedCollectionGroupItemData = async (id, type, limit, offset) => {
    const expandLevel = 2
    const order = 'DESC'
    const sortKeys = 'episode_number'
    let fullItemData = {}

    if (type === 'LibraryCollection') {
        fullItemData = await remocoClient.getCollection(id, expandLevel, limit, offset, order, sortKeys).catch(err => {
            console.error('Error retrieving collection:', err)
        })
    } else if (type === 'AdminCategory') {
        fullItemData = await remocoClient.getCategory(id, expandLevel, limit, offset, order, sortKeys).catch(err => {
            console.error('Error retrieving category:', err)
        })
    }

    return fullItemData
}

const getRelevantCollGroupData = async collGroupItem => {
    const entries = get(['data', 'entries', 'list'], collGroupItem)

    if (!entries) {
        console.error('Entries not found for collection item:', collGroupItem)
        return
    }

    const releventEntryData = entries.map(getRelevantItemData)
    const { id } = collGroupItem
    const { title } = collGroupItem.data

    return {
        id,
        title,
        data: releventEntryData,
    }
}

async function getAssetData(id) {
    const res = await remocoClient.getLibraryEventById(id)

    if (!res.data) {
        throw new Error('Unable to retrieve asset detail data for ID:', id)
    }

    const categories = get(['data', 'categories', 'list'], res)
    const relevantAssetData = getRelevantItemData(res)

    return {
        ...relevantAssetData,
        categories,
    }
}

function getHeroImageSize(imgUrl, imgVw) {
    if (!imgUrl) {
        return
    }

    const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
    const width = (vw / 100) * imgVw
    const height = width * 0.5625
    const newDimens = `${width}x${height}`

    const newImgUrl = imgUrl.replace(/\/original.([a-z]+)$/, `/${newDimens}.$1`)

    return newImgUrl
}

module.exports = {
    get,
    getAssetData,
    getExpandedCollectionGroupItemData,
    getHeroImageSize,
    getHref,
    getIdentifierFromPath,
    getRelevantCollGroupData,
    getRelevantEntriesData,
    getRelevantItemData,
}
