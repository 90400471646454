const debounce = require('lodash.debounce')
const trackCustomEvent = require('gatsby-plugin-google-analytics').trackCustomEvent
const { get } = require('./get')
const { getCurrentItem } = require('./player')
const remocoClient = require('../remocoClient')
const actionTypes = require('../state/actionTypes').default
const createStore = require('../state/createStore').default

const sendGaEvents = (player, contentViewThreshold, seriesEventCode) => {
    const onTimeUpdate = getOnTimeUpdateHandler(player, contentViewThreshold, seriesEventCode)

    const onPlay = () => {
        const currentItem = getCurrentItem(player)
        const { eventCode, id, title, seriesId } = currentItem

        sendGaEvent('Content Start', id, eventCode, seriesEventCode, title, null, seriesId)
        player.on('timeupdate', onTimeUpdate)
        // resetting item change handler here so that it's only executed once per video
        // TODO: investigate root cause of 'playlistitem' event getting fired twice
        player.one('playlistitem', onPlaylistItemChange)
    }

    const onPlaylistItemChange = () => {
        player.one('play', onPlay)
    }

    const onEnded = () => {
        sendContentStopEvent(player, getCurrentItem(player))
    }

    const onError = () => {
        const currentItem = getCurrentItem(player)
        const error = player.error()
        const errorMsg = error ? error.message : '(not set)'
        const ev = {
            category: 'VideoJS error',
            action: JSON.stringify(currentItem),
            label: `Error message: ${errorMsg}`,
        }

        trackCustomEvent(ev)
    }

    player.one('playlistitem', onPlaylistItemChange)
    player.on('ended', onEnded)
    player.on('error', onError)

    return () => {
        player.off('timeupdate', onTimeUpdate)
        player.off('play', onPlay)
        player.off('playlistitem', onPlaylistItemChange)
        player.off('ended', onEnded)
        player.off('error', onError)
    }
}

const getOnTimeUpdateHandler = (player, contentViewThreshold, seriesEventCode) => {
    const onTimeUpdateHandler = debounce(
        ev => {
            const tech = player.tech({ IWillNotUseThisInPlugins: true })
            const hasVideoEl = !!tech && tech.el_

            if (hasVideoEl) {
                const p = player.played()
                const elapsed = getTotalTimeElapsed(p)

                if (elapsed > contentViewThreshold) {
                    const currentItem = getCurrentItem(player)
                    const { eventCode, id, title, seriesId } = currentItem

                    sendGaEvent('Content View', id, eventCode, seriesEventCode, title, null, seriesId)
                    // remove this event listener
                    player.off('timeupdate', onTimeUpdateHandler)
                }
            }
        },
        1000,
        {
            maxWait: 2000,
            leading: true,
        }
    )

    return onTimeUpdateHandler
}

const getTotalTimeElapsed = ranges => {
    const len = ranges.length

    return [...Array(len).keys()].reduce((acc, curr) => {
        const diff = ranges.end(curr) - ranges.start(curr)

        return diff + acc
    }, 0)
}
const sendContentStopEvent = (player, currentItem) => {
    const { eventCode, id, seriesEventCode, title, seriesId } = currentItem

    if (player !== null && typeof player.played === 'function') {
        const p = player.played()
        const elapsed = getTotalTimeElapsed(p)

        sendGaEvent('Content Stop', id, eventCode, seriesEventCode, title, elapsed, seriesId)
    } else {
        console.warn('Unable to send custom event:', player)
    }
}

const sendGaEvent = async (eventCategory, id, eventCode, seriesEventCode, title, value, seriesId) => {
    const intVal = typeof value === 'number' ? Math.floor(value) : null
    const seriesCode = seriesEventCode || (await getSeriesCode(seriesId))

    const ev = {
        category: eventCategory,
        action: `${seriesCode || '(not set)'}`,
        // eventCode is value of "event_code" field on Ediflo asset
        label: `${title || id || '(not set)'} - ${eventCode || '(not set)'}`,
        ...(typeof intVal === 'number' && { value: intVal }),
    }

    console.log('sending GA ev:', ev)
    trackCustomEvent(ev)
}

const getSeriesCode = async seriesId => {
    const store = createStore()

    if (!store) {
        console.warn('Store undefined.')
    }
    const state = store.getState()
    const seriesCode = get(['seriesIdsToCodesMap', seriesId], state)

    return seriesCode || (await fetchSeriesCode(seriesId, store))
}

const fetchSeriesCode = async (seriesId, store) => {
    if (!seriesId) {
        console.error('Unable to retrieve series code. Series ID undefined.')
        return
    }

    const seriesData = await remocoClient.getSeriesById(seriesId)
    const seriesCode = get(['data', 'series_code'], seriesData)

    store.dispatch({
        type: actionTypes.SET_SERIES_CODE,
        seriesIdToCodeMap: { [seriesId]: seriesCode },
    })

    return seriesCode
}

function sendGaPlayClickEvent(itemTitle, edifloId, btnTitle) {
    const label = `${itemTitle || edifloId || '(not set)'} - ${btnTitle}`

    trackCustomEvent({
        category: 'Navigation',
        action: `Select Button - ${btnTitle}`,
        label,
    })
}

function setGaScreenName(name) {
    if (typeof window !== 'undefined' && typeof window.ga === 'function') {
        window.ga('set', 'screenName', name)
    }
}

export { sendContentStopEvent, sendGaEvents, sendGaPlayClickEvent, setGaScreenName }
