const remocoWebComponentsLoader = require('@axonista/web-components/loader')
require('cookieconsent')
const trackCustomEvent = require('gatsby-plugin-google-analytics').trackCustomEvent
const Cookies = require('js-cookie').default
const netlifyIdentity = require('netlify-identity-widget')

const actionTypes = require('./src/state/actionTypes').default
require('./src/cookie-consent-modal.css')
const remocoClient = require('./src/remocoClient')
const createStore = require('./src/state/createStore').default
const get = require('./src/utils').get

exports.onClientEntry = () => {
    remocoWebComponentsLoader.defineCustomElements(window)

    if (typeof window.ga === 'function') {
        window.ga('set', 'appName', 'Web')
    }

    setConfigData().then(config => {
        isUserInEu().then(isInEu => {
            if (isInEu !== false) {
                // show consent dialog unless user is definitely not in EU
                setUpCookieConsent(config)
            } else {
                // go ahead and use cookies when user is not in EU
                Cookies.set('cookieconsent_status', 'allow')
            }
        })

        preloadSpotXSdk(config.adRules.publisher_id)
    })
    setWindowVariables()

    netlifyIdentity.init()

    setPrerenderReadyFlag()
}

const setPrerenderReadyFlag = () => {
    // set flag for Netlify Prerender service to indicate when the page is ready to prerender
    if (typeof window !== 'undefined') {
        window.prerenderReady = false

        setTimeout(() => {
            // ensure it's eventually set to true in case it doesn't get set by SEO component
            window.prerenderReady = true
        }, 10000)
    }
}

const setWindowVariables = () => {
    if (typeof window !== 'undefined') {
        // TODO: replace use of window variables with a combination of Redux state & refs
        window.VideoElephant = {}
        window.VideoElephant.hasFirstVideoPlayedYet = false
        window.VideoElephant.videosPlayedSinceLastAd = 0
    }
}

const preloadSpotXSdk = publisherId => {
    const tag = document.createElement('link')
    tag.href = `https://js.spotx.tv/directsdk/v1/${publisherId}.js`
    tag.onload = () => {
        console.log('SpotX SDK pre-loaded...')
    }
    tag.onerror = err => {
        console.error('Unable to pre-load SpotX SDK', err)
    }
    tag.as = 'script'
    tag.rel = 'preload'
    const firstLinkTag = document.getElementsByTagName('link')[0]
    firstLinkTag.parentNode.insertBefore(tag, firstLinkTag)
}

async function isUserInEu() {
    const geoData = await remocoClient.getGeoip()
    const isInEu = get(['data', 'eu'], geoData)

    return isInEu
}

function setConfigData() {
    const store = createStore()
    if (!store) {
        console.error('store not initialized')
        return
    }

    return remocoClient
        .getConfig()
        .then(config => {
            const relevantConfig = getRelevantConfigData(config)
            store.dispatch({
                type: actionTypes.SET_CONFIG_DATA,
                config: relevantConfig,
            })

            return relevantConfig
        })
        .catch(err => {
            console.error('Error retrieving config:', err)
        })
}

function getRelevantConfigData(config) {
    const contentViewThreshold = get(['data', 'custom_data', 'custom', 'content_view_threshold_seconds'], config) || 10
    const cookieConsentMsg = get(['data', 'custom_data', 'custom', 'data_consent_screen_body'], config)
    const cookieConsentTitle = get(['data', 'custom_data', 'custom', 'data_consent_screen_title'], config)
    const adRules = get(['data', 'custom_data', 'custom', 'ad_rules'], config)

    return {
        adRules,
        contentViewThreshold,
        cookieConsentMsg,
        cookieConsentTitle,
    }
}

function setUpCookieConsent(config = {}) {
    if (!window.cookieconsent || !config.cookieConsentMsg || !config.cookieConsentTitle) {
        throw new Error('Unable to display cookie consent dialog')
    }

    window.cookieconsent.initialise({
        palette: {
            popup: {
                background: '#141414',
                text: '#ffffff',
            },
            button: {
                background: '#ffffff',
                text: '#141414',
            },
        },
        type: 'opt-in',
        revokable: false,
        content: {
            header: config.cookieConsentTitle,
            message: config.cookieConsentMsg,
            allow: 'ACCEPT COOKIES',
            deny: 'DECLINE COOKIES',
            href: '/privacy',
            link: 'Learn more',
        },
        layouts: {
            basic: '{{header}}{{message}}{{compliance}}',
        },
        law: {
            regionalLaw: false,
        },
        location: false,
        theme: 've-custom',
        onPopupOpen: function (status) {
            document.querySelector('#___gatsby').style.opacity = 0.4
            document.querySelector('#___gatsby').style.pointerEvents = 'none'
        },
        onPopupClose: function () {
            document.querySelector('#___gatsby').style.opacity = 1
            document.querySelector('#___gatsby').style.pointerEvents = 'auto'
        },
        onStatusChange: onCookieConsentStatusChange,
    })
}

function onCookieConsentStatusChange(status) {
    const consented = this.hasConsented()
    // audit trail of consent required for full GDPR compliance
    // handleGaConsentEvent(consented)

    dispatchCookieConsentStatus(consented)

    if (!consented) {
        if (typeof gaOptout === 'function') {
            // eslint-disable-next-line
            gaOptout() // opt out fn set globally by Gatsby Google Analytics plugin
        }
    }
}

function dispatchCookieConsentStatus(consented) {
    const store = createStore()

    if (!store) {
        console.error('store not initialized')
        return
    }

    store.dispatch({
        type: actionTypes.SET_COOKIE_CONSENT_STATUS,
        consented: !!consented,
    })
}

// eslint-disable-next-line
function handleGaConsentEvent(consented, config = {}) {
    const consentData = {
        time: Date.now(),
        message: config.cookieConsentMsg,
        method: 'cookie consent dialog',
    }
    const eventLabel = JSON.stringify(consentData)

    trackCustomEvent({
        category: 'cookie-consent',
        action: consented ? 'granted' : 'declined',
        label: eventLabel,
    })
}
